<template>
  <div class="content">
    <van-nav-bar style="background-color: var(--navBg);height: calc(50rem / 16);">
      <template #title>
        <span class="tabbar_title">{{ $t('newTabbar.shopPage.title') }}</span>
      </template>
    </van-nav-bar>
    <div class="shop" v-if="shopArr.length >0">
      <van-grid :column-num="3" gutter="10px" :border="false">
        <van-grid-item v-for="(item, index) in (shopArr.length > 0 ? shopArr : 6)" :key="index" class="grid_item">
          <div class="item" @click="gameClick(item)">
            <img :src="$img(item.icon)" alt="" style="height: calc(70rem / 16);">
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <Tabbar active="make-money" />
  </div>
</template> 

<script>
import { NavBar, Grid, GridItem, Toast } from "vant";
import Tabbar from "@/components/Tabbar";
import { mapGetters } from "vuex";

export default {
  components: {
    Tabbar,
    [NavBar.name]: NavBar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    Toast
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters(["userInfo", 'shopArr'])
  },
  created() {
  },
  mounted() {
  },
  methods: {
    gameClick(item) {
      if(this.userInfo.disable_lottery == 1) return Toast(this.$t('gameToast'));
      item.icon && this.$router.push(`/games?code=${item.code}`)
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f4f8fb;
  min-height: calc(100vh - (50rem / 16));

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .tabbar_title {
    color: #fff;
    font-weight: 400;
    font-size: calc(14rem / 16);
  }

  .shop {
    padding: calc(6rem / 16);
    min-height: calc(100vh - (112rem / 16));

    /deep/ .van-grid {
      padding-left: 0 !important;
    }

    .grid_item {
      /deep/ .van-grid-item__content {
        padding: 0;
        border-radius: calc(8rem / 16);
      }

      .item {
        img {
          width: 100%;
          height: 100%;
          display: block;
          margin-right: calc(12rem / 16);
        }
      }
    }

    .grid_item:nth-child(3n) {
      padding-right: 0 !important;
    }
  }
}
</style>